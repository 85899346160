<header>
  <div class="w3layouts-top-strip">
    <div class="container">
      <div class="logo">
        <img src="assets/images/logo.png" alt="Estilo Glazer" title="Estilo Glazer" width="750px;">
      </div>
      <div class="w3ls-social-icons">
        <a class="facebook" href="#"><i class="fa fa-facebook"></i></a>
        <a class="twitter" href="#"><i class="fa fa-twitter"></i></a>
        <a class="instagram" href="#"><i class="fa fa-instagram"></i></a>
      </div>
    </div>
  </div>
  <nav class="navbar navbar-default">
    <div class="container">
      <!-- Brand and toggle get grouped for better mobile display -->
      <div class="navbar-header">
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>

      <!-- Collect the nav links, forms, and other content for toggling -->
      <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
        <ul class="nav navbar-nav">
          <li><a [routerLink]="['/']">Home</a></li>
          <li><a [routerLink]="['/conoceme']">Conoceme</a></li>
          <li><a [routerLink]="['/servicios']">Servicios</a></li>
          <li><a [routerLink]="['/shop']">Shop</a></li>
          <li><a [routerLink]="['/freebies']">Freebies</a></li>
          <li><a [routerLink]="['/contacto']">Contacto</a></li>
        </ul>
      </div><!-- /.navbar-collapse -->

    </div><!-- /.container-fluid -->
  </nav>
</header>
<div class="clearfix">
  <div class="container">
    <div class="banner-btm-agile">
      <div class="col-md-12 banner-btm-agile">
        <div class="courses">
          <div class="agileits_w3layouts_team_grids w3ls_courses_grids">
            <div class="col-md-12">
              <img src="assets/images/404.jpg" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
<div class="banner-bottom" style="display: flex; margin: 0; justify-content: center; align-items: center">
  <img src="assets/images/bottom-actions.png" width="80%" alt="">
</div>
<div class="footer-agile-info">
  <div class="container">
    <div class="w3ls-social-icons-bottom">
      <a class="facebook" href="#"><i class="fa fa-facebook"></i></a>
      <a class="twitter" href="#"><i class="fa fa-twitter"></i></a>
      <a class="instagram" href="#"><i class="fa fa-instagram"></i></a>
    </div>
    <div class="col-md-2 w3layouts-footer"></div>
    <div class="col-md-4 w3layouts-footer">
      <div class="menu-footer">
        <p><a [routerLink]="['/']">Home</a></p>
        <p><a [routerLink]="['/conoceme']">Conoceme</a></p>
        <p><a [routerLink]="['/servicios']">Servicios</a></p>
        <p><a [routerLink]="['/shop']">Shop</a></p>
        <p><a [routerLink]="['/freebies']">Freebies</a></p>
        <p><a [routerLink]="['/contacto']">Contacto</a></p>
      </div>
    </div>
    <div class="col-md-2 wthree-footer">
    </div>
    <div class="col-md-4 w3-agile">
      <p><strong>UNETE A MI LISTA</strong> te enviare muchas sorpresas</p>
      <form action="#" method="post">
        <input type="email" name="Email" placeholder="Correo electronico" required="">
        <input type="submit" value="SUSCRIBETE">
      </form>
    </div>
  </div>
</div>
